<template>
  <el-row class="table">
    <span class="title">学员课时券管理</span>
    <el-row class="row-bg">
        <el-col :span="4" style="margin-left: 15px;">
          <el-input placeholder="请输入内容" v-model="nameorcard" >
            <el-select
              style="width: 80px;"
              v-model="selectname"
              slot="prepend"
              placeholder="请选择"
            >
              <el-option label="姓名" value="1"></el-option>
              <el-option label="学号" value="2"></el-option>
            </el-select>
          </el-input>
        </el-col>
        <el-col  :span="3"  style="margin-left: 25px;">
          <el-select
            style="width: 150px;"
            v-model="selectclass"
            placeholder="请选择"
          >
            <el-option v-for="item in  classState" :key="item.couponNum" :label="item.couponNum" :value="item.couponNum"></el-option>
          </el-select>
        </el-col>
        <el-col  :span="3"  style="margin-left: 25px;">
          <el-select
            style="width: 150px;"
            v-model="selectstate"
            placeholder="请选择"
          >
            <el-option v-for="item in stateList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col  :span="4" :offset="1">
          <el-button type="primary" size="medium" @click="searchDataList()" class="tb-button">搜索</el-button>
          <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
        </el-col>
        <el-col :span="2">
         <span class="btn btn-success fileinput-button">
            <el-button type="primary">导入文件</el-button>
             <input type="file" ref="upload" accept=".xls,.xlsx" @change="readExcel"/>
           </span>
        </el-col>
        <el-col :span="2">
         <span class="btn btn-success fileinput-button">
            <el-button type="primary" @click="downloadFile">下载文件模板</el-button>
        </span>
        </el-col>
      </el-row>
  <el-table
    style="margin-top: 20px;width: 45%;"
    :data="dataList"
    border
    fit
    highlight-current-row
    class="tb-list"
  >
    <el-table-column label="编号" align="center">
      <template slot-scope="scope">
        {{scope.row.couponUserNum}}
      </template>
    </el-table-column>
    <el-table-column label="类型" align="center">
      <template slot-scope="scope">
        {{scope.row.coupon.couponNum}}
      </template>
    </el-table-column>
    <el-table-column label="学员" align="center">
      <template slot-scope="scope">
        {{scope.row.userName}} [{{scope.row.loginName}}]
      </template>
    </el-table-column>
    <el-table-column label="使用状态" align="center">
      <template slot-scope="scope">
        {{showStatus(scope.row.status)}}
      </template>
    </el-table-column>
  </el-table>
    <el-pagination
      style="width: 50%;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
    import XLSX from 'xlsx'
    import {patchBindCouponByUser,getUserCouponList,getCouponList}  from '@/api/system/coupon'
    export default {
        name: "cousehour-history",
      data(){
          return{
            outputs: [],
            dataList:[],
            selectname:"1",
            nameorcard:"",
            selectstate:"",
            stateList:[{id:"",name:"全部状态",},{id:1, name:"待使用",},{id:2, name:"已使用",}],
            classState:[],
            selectclass:"全部类型",
            pagination: {
              currentPage: 1,
              pageSize: 10,
              pageSizes: [10, 30, 50, 100],
              layout: 'total, sizes, prev, pager, next, jumper',
              total: 0
            }
          }
      },
      created() {
          this.getUserCouponList()
          this.getCouponList()
      },
      methods: {
        reset() {
          this.selectname = "1"
          this.nameorcard = ""
          this.selectstate = ""
          this.selectclass = "全部类型"
        },
        searchDataList() {
          this.pagination.currentPage = 1
          this.getUserCouponList()
        },
        async getCouponList () {
          const res = await getCouponList({})
          this.classState = res.body.list
          var allObject = {}
          allObject.couponNum = "全部类型"
          this.classState.splice(0,0,allObject)
        },
          downloadFile() {
            let link = document.createElement('a')  // 创建a标签
            link.style.display = 'none'
            link.href = process.env.VUE_APP_FILE+ "/file/学员课时抵扣卷模板.xlsx" // 设置下载地址
            link.setAttribute('download', '') // 添加downLoad属性
            document.body.appendChild(link)
            link.click()
          },
        readExcel (e) {
          let that = this
          const files = e.target.files
          if (files.length < 1) {
            return false
          } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
            $msg('上传文件格式不正确，请上传xls或者xlsx格式')
            return false
          }
          console.log("data-----------"+e)
          const fileReader = new FileReader()
          fileReader.onload = (ev) => {
            try {
              const data = ev.target.result
              const workbook = XLSX.read(data, {
                type: 'binary',
              }) // 读取数据
              const wsname = workbook.SheetNames[0] // 取第一张表
              const sheet2JSONOpts = {
                /** Default value for null/undefined values */
                defval: ''//给defval赋值为空的字符串
              }
              const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname],sheet2JSONOpts) // 生成json表格内容
              // const ws1 = XLSX.utils.sheet_to_slk(workbook.Sheets[wsname]) // 输出表格对应位置是什么值
              // const ws2 = XLSX.utils.sheet_to_html(workbook.Sheets[wsname]) // 生成HTML输出
              // const ws3 = XLSX.utils.sheet_to_csv(workbook.Sheets[wsname]) // 生成分隔符分隔值输出
              // const ws4 = XLSX.utils.sheet_to_formulae(workbook.Sheets[wsname]) // 生成公式列表（具有值回退）
              // const ws5 = XLSX.utils.sheet_to_txt(workbook.Sheets[wsname]) // 生成UTF16格式的文本

              let arr = [] // 清空接收数据
              var uploadnot = false
              console.log(JSON.stringify(ws))
              ws.map((v,index) => {

                let obj = {}

                obj.couponUserNum = v.课时券编号
                obj.couponNum = v.课时券类型
                obj.loginName = v.学号
                if(obj.couponUserNum === "" || obj.couponNum === ""|| obj.loginName === "" ) {
                  $msg("第"+index+"行有必填的数据项为空，请检查后再上传",1)
                  uploadnot = true
                }
                arr.push(obj)
              })

              if(!uploadnot) {
                that.patchBindCouponByUser(arr)
              }
              this.$refs.upload.value = null
            } catch (e) {
              console.log(e)
              return false
            }
          }
          fileReader.readAsBinaryString(files[0])
        },
        showStatus(status) {
          switch (status) {
            case "1":
              return "待使用"
              break;
            case "2":
              return "已使用"
              break;
          }
        },
        async patchBindCouponByUser (array) {
          const res = await patchBindCouponByUser(JSON.stringify(array))
          if(res && res.state === "success") {this.getUserCouponList()}
        },
        async getUserCouponList () {
          const res = await getUserCouponList({
            pageNum: this.pagination.currentPage,
            pageSize: this.pagination.pageSize,
            needCount: true,
            loginName:this.selectname == 2?this.nameorcard:"",
            nickName:this.selectname == 1?this.nameorcard:"",
            couponNum:this.selectclass === '全部类型'?"":this.selectclass,
            status:this.selectstate,
          })
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        },
        // 分页
        handleSizeChange (val) {
          this.pagination.pageSize = val
          this.getUserCouponList()
        },
        handleCurrentChange (val) {
          this.pagination.currentPage = val
          this.getUserCouponList()
        },
      }
    }
</script>

<style scoped lang="scss">
  @import "../../style/table.scss";
  .fileinput-button {
    position: relative;
    display: inline-block;
    overflow: hidden;
  }

  .fileinput-button input{
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    -ms-filter: 'alpha(opacity=0)';
  }

</style>

